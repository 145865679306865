import React from 'react'
import icoinstagram from "../img/instagram.svg"
import icomessenger from "../img/messenger.svg"
import icolinkedin from "../img/linkedin.svg"
import { links } from "../content/links"

const Footer = () => {
  const { phone, messenger, instagram, linkedin, mail } = links

  function formatPhoneNumber(phone) {
    return phone.replace(/(\+420)(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4');
  }

  return (
    <footer className='container'>
      <h1>Dalibor Janeček, Olomouc,&nbsp;CZ</h1>
      <div id='contact' className="contact">
        <p>
          <a href={`tel:${phone}`}>tel {formatPhoneNumber(phone)}</a>
          <br />
          <a href={`mailto:${mail}`}>{mail}</a>
        </p>
        <p className='social'>
          <a href={`${instagram}`} target='_blank' rel="noreferrer"><img src={icoinstagram} alt="" /></a>
          <a href={`${messenger}`} target='_blank' rel="noreferrer"><img src={icomessenger} alt="" /></a>
          <a href={`${linkedin}`} target='_blank' rel="noreferrer"><img src={icolinkedin} alt="" /></a>
        </p>
      </div>
    </footer>
  )
}

export default Footer