import React from 'react'
import photo from "../img/photo.jpg"

const Hero = () => {
    return (
        <section className='container hero'>
            <img id='photo' src={photo} alt="Dalibor Janeček - designer" />
            <h2 id='kreslim'>Kreslím<span className='service'>UI</span></h2>
            <div id="tlacitka">
                <h2>tlačítka</h2>
                <h3 id='prinasim'>Přináším vizuální zážitky v&nbsp;aplikacích <br />s&nbsp;kreativním designem.</h3>
            </div>
        </section>
    )
}

export default Hero