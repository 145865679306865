import React from 'react'
import instagram from "../img/instagram.svg"
import videoriva from "../media/rivaman.mp4"
import videogustav from "../media/gustavsleeptimer.mp4"
import videorodaci from "../media/rodaci.mp4"
import { links } from "../content/links"

const Motion = () => {
  return (
    <section className='container motion'>
            <h2 id='pohybuji'>Oživuji <span id='motion' className="service">motion</span></h2>
            <h3 id='tvorim'>Tvořím poutavé animace s&nbsp;důrazem na&nbsp;kreativní design.
                <br />
                <a href={links.instagram} target='_blank' rel="noreferrer"><img src={instagram} alt="" /></a>
            </h3>
            <div className="video">
                <video playsInline autoPlay muted loop><source src={videoriva} type="video/mp4" /></video>
                <div className="description">
                    <h4>Riva Man</h4>
                    <p>Reels video prezentující služby masážního a kosmetického salonu RiVa.</p>
                </div>
            </div>

            <div className="video">
            <video playsInline autoPlay muted loop><source src={videogustav} type="video/mp4" /></video>
                <div className="description">
                    <h4>Gustav sleep timer</h4>
                    <p>Aprílové video, které vtipně zaměňuje aplikaci pro spánek za Gustav Workout Timer, hlídající intervaly během cvičení.</p>
                </div>
            </div>

            <div className="video">
            <div className="description">
                    <h4>Za slavnými rodáky</h4>
                    <p>Animace turistické soutěže pro pacienty, která podporuje pohyb a objevování míst.</p>
                </div>
                <video playsInline autoPlay muted loop><source src={videorodaci} type="video/mp4" /></video>
            </div>
        </section>
  )
}

export default Motion