import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Footer from './components/Footer';
import Apps from './components/Apps';
import Motion from './components/Motion';
import Freebies from './components/Freebies';

function App() {
  return (
    <div>
      <Header />
      <Hero />
      <Apps />
      <Motion />
      <Freebies />
      <Footer />

    </div>
  );
}

export default App;