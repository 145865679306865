import React from 'react'
import dj2025 from "../img/dj2025.svg"
import NavBar from "./NavBar"

const Header = () => {
  return (
    <header className='container'>
        <img src={dj2025} alt="" className="logo" />
        <NavBar />
    </header>
  )
}

export default Header