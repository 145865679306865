import React from 'react'
import { links } from "../content/links"

const NavBar = () => {
  const { mail } = links;

  return (
    <nav>
        <div className="menu">
          <a href='#apps'>aplikace</a>
          <a href='#motion'>motion</a>
          <a href='#freebies'>freebies</a>
        </div>
        <div className="contact">
          <a href={`mailto:${mail}`} className="mail">{mail}</a>
        <a href='#contact' className='highlight'>spolupracujme</a>
        </div>
    </nav>
  )
}

export default NavBar