import React from 'react'
import placeholder from "../img/placeholder.jpg"
import appstorebadge from "../img/appstorebadge.svg"
import { applinks } from "../content/links"

const Apps = () => {
    const { timer, weights } = applinks;

    return (
        <section className='container apps'>
            <img id='apps' className='apps-video' src={placeholder} alt="" />
            <div className="app-detail">
                <h4>Gustav Timer <span className='app-platform'>(iOS)</span></h4>
                <p>Aplikace Gustav Workout Timer je váš ideální tréninkový partner, který nabízí flexibilitu a uživatelsky přívětivý zážitek.</p>
                <a href={timer} target='_blank' rel="noreferrer"><img src={appstorebadge} alt="" /></a>
            </div>
            <div className="app-detail">
                <h4>Gustav Weights <span className='app-platform'>(iOS)</span></h4>
                <p>Gustav Weights je nezbytným společníkem pro cvičení! Zaznamenává odcvičené váhy a monitoruje pokrok.</p>
                <a href={weights} target='_blank' rel="noreferrer"><img src={appstorebadge} alt="" /></a>
            </div>
        </section>
    )
}

export default Apps