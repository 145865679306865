import React from 'react'
import icounsplash from "../img/unsplash.svg"
import { links } from "../content/links"


const Freebies = () => {
  const { unsplash } = links
  return (
    <section className="container freebies">
        <h2>Dávám<span id='freebies' className='service'>freebies</span></h2>
        <h3>Je to můj způsob, jak podpořit kreativní tvůrce a vrátit něco zpět tam, odkud čerpám.</h3>
        <div className="free-links">
            <a href={unsplash} target='_blank' rel="noreferrer"><img src={icounsplash} alt="" /></a>
        </div>
    </section>
  )
}

export default Freebies