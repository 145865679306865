export const links = {
    phone: "+420739554085",
    messenger: "https://m.me/daliborecek",
    instagram: "https://www.instagram.com/daliborjanecek.cz/",
    linkedin: "https://www.linkedin.com/in/daliborjanecek/",
    mail: "ahoj@daliborjanecek.cz",
    unsplash: "https://unsplash.com/@daliborjanecek"
}

export const applinks = {
    timer: "https://apps.apple.com/us/app/gustav-workout-timer/id6478176431",
    weights: "https://apps.apple.com/us/app/gustav-weights/id6483001116"
}